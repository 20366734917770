// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
const Terms = () => {
    return (
    <>
    <div className="WordSection1" style={{padding:"150px 10px"}}>
      <h2 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <span style={{ fontSize: '14.0pt', lineHeight: '  40px', fontFamily: 'Helvetica'}}>Terms of Use</span>
      </h2>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica' }}>1. Terms</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px'}}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        By accessing Asgarzade Music School, you are agreeing to be bound by these Terms of Use, and all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this School are protected by applicable copyright and trademark law.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>2. Use License</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        &nbsp;&nbsp;&nbsp;   1.&nbsp; Permission is granted to temporarily download one copy of any downloadable materials on the School’s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:
        </span>
      </p>

      <ol style={{marginLeft:"5%",}}>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>1.&nbsp;&nbsp;&nbsp;modify or copy the materials;</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>2.&nbsp;&nbsp;&nbsp;use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>3.&nbsp;&nbsp;&nbsp;attempt to decompile or reverse engineer any software contained on the School’s website;</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>4.&nbsp;&nbsp;&nbsp;remove any copyright or other proprietary notations from the materials; or</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>5.&nbsp;&nbsp;&nbsp;transfer the materials to another person or 'mirror' the materials on any other server.</span>
        </li>

      </ol>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        &nbsp;&nbsp;&nbsp;   2.&nbsp;  This license shall automatically terminate if you violate any of these restrictions and may be terminated by the Company at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
        </span>
      </p>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>3. Use of the Asgarzade Music School courses. </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        &nbsp;&nbsp;&nbsp;Students will get full lifetime access to the following upon enrollment in any course of the Asgarzade Music School:
        </span>
      </p>
      <ol style={{marginLeft:"5%",}}>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>.&nbsp;&nbsp;&nbsp;All available and future video lectures <b>– non downloadable.</b></span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>.&nbsp;&nbsp;&nbsp;All available and future PDF files <b>– downloadable.</b></span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>.&nbsp;&nbsp;&nbsp;All available and future Word files (for English subtitles) <b>–  downloadable.</b></span>
        </li>
      </ol>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>4.  Disclaimer </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        The materials on the School’s website are provided 'as is'. The School makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, the School does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
        </span>
      </p>
      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>5.  Limitations </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        In no event shall the School be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on the School’s website, even if the School or an authorized of the School has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
        </span>
      </p>
      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>6.Revisions and Errata </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        The materials appearing on the School’s website may include technical, typographical, or photographic errors. The School does not warrant that any of the materials on its website are accurate, complete, or current. The School may make changes to the materials contained on its website at any time without notice. The School does not, however, make any commitment to update the materials.

        </span>
      </p>



      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>7.  Links </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        The School has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by the School of the site. Use of any such linked website is at the user's own risk.
        </span>
      </p>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>8.  Site Terms of Use Modifications </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        The School may revise these Terms of Use for its website at any time without notice. By using this website you are agreeing to be bound by the then-current version of these Terms of Use.
        </span>
      </p>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>9.  Governing Law </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        Any claim relating to the School’s website shall be governed by the laws of the School Owner’s home jurisdiction without regard to its conflict of law provisions.
        </span>
      </p>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>10. Copyright Disclaimer  </span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        Asgarzade Music School does not own the copyright on any of the used softwares, plugins, and VSTs used in the courses, course intros course example pictures, and trailers, used on the website itself or on social media platforms. The tutors own a license for the used products.
        </span>
      </p>
<span>
“Asgarzade Music School” Online Educational Platform    <br></br>                                         
Owner: Rovshan Asgarzade  Azerbaijan / Baku / Yasamal District / H.Javid avenue / 46/79 <br></br>  
AZ1141 <br></br>  
Tax ID: AZ 8503481572  <br></br>  
Baku, November 2023
</span>
    </div>

    </>
    )

}

export default Terms