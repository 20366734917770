import { useEffect } from "react";
import {setAuthenticationStatus} from "./auth"
import axios from "axios";
import {setLoadingStatus,getLoadingStatus} from "./checkRefresh";

const useTokenCheck =  (onComplete) => {
   useEffect(()=>{
  
    const token = localStorage.getItem("token");
    if (token!=null) {
         const refreshToken=localStorage.getItem("refreshToken")
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          const encodedRefreshToken = encodeURIComponent(refreshToken);
             axios.get(`https://api.asgarzademusicschool.com/api/v1/Auth/RefreshTokenLoginAsync?refreshToken=${encodedRefreshToken}`)
             .then(res=>{
              localStorage.setItem('token', res.data.accessToken);
              localStorage.setItem('refreshToken', res.data.refreshToken);
              setAuthenticationStatus(true)
              if (onComplete) onComplete();
              if (getLoadingStatus()) setLoadingStatus(false);
             }).catch(error=>{
              setAuthenticationStatus(false)
              if (onComplete) onComplete();
              if (getLoadingStatus()) setLoadingStatus(false);
             })
    }else{
      setAuthenticationStatus(false)
           if (onComplete) onComplete();
           if (getLoadingStatus()) setLoadingStatus(false);
    }
   },[])
  };
  export default useTokenCheck;

