// eslint-disable-next-line no-unused-vars
import React from 'react'
import "./Footer.scss"
import { ReactComponent as CopyRight } from "./../../../assets/svg/Copyright.svg"

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-container">
                    <div className='lside-footer'>
                        <CopyRight />
                        <span>Asgarzade Music School
                        &#160;  {new Date().getFullYear()}
                        </span>
                        
              
  
                    </div>
                    <div className='rside-footer'>
                        <a style={{color:"black"}} href='/termsof' >Terms for use</a>
                        <a style={{color:"black"}} href='/privacypolicy'>Privacy policy</a>

                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer