import React, { useState, useEffect, useRef } from 'react';
import "./Cards.scss";

const Cards = () => {
    const [composition, setComposition] = useState([]);
    const cardsRef = useRef(null);
    const [shouldFetch, setShouldFetch] = useState(false);

    const fetchData = () => {
        fetch("https://api.asgarzademusicschool.com/api/v1/Composition")
            .then(res => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then(r => setComposition(r.items))
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error.message);
            });
    };

    useEffect(() => {
        if (shouldFetch) {
            fetchData();
        }
    }, [shouldFetch]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setShouldFetch(true);
                }
            },
            { threshold: 0.1 }
        );

        if (cardsRef.current) {
            observer.observe(cardsRef.current);
        }

        return () => {
            if (cardsRef.current) {
                observer.unobserve(cardsRef.current);
            }
        };
    }, []);

    return (
        <section className='cards-section' ref={cardsRef}>
            <div className="container">
            <h2>
            School   <span className='special-header-text'>Rules</span>
                    </h2>
                <ul className='cards-section-container'>
                    {composition.map((item, index) => (
                        <li key={index}>
                            <div className='top-side'>
                                <div className='background' dangerouslySetInnerHTML={{ __html: item.icon }}>
                                </div>
                                <h6>{item.title}</h6>
                            </div>
                            <p>
                                {item.description} 
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default Cards;
