import React,{useEffect} from 'react'
import { Helmet } from 'react-helmet-async'
import HeroSection from './../components/Main/Downloads/HeroSection'
import Cards from './../components/Main/Downloads/Cards'
import { fetchSettingss } from '../redux/actions/settingsActions'
import { useDispatch, useSelector } from 'react-redux';


const Downloads = () => {
  const dispatch = useDispatch()
  const { data } = useSelector((state) => state.settings)
  useEffect(() => {
      dispatch(fetchSettingss());
     
  }, [dispatch]);
  const learnText = data?.data?.learnText || 'Default Hero File';
  const learnFile = data?.data?.learnFilePath || 'Default Hero File';
  

  return (
    <>
      <Helmet>
        <title>Downloads | Asgarzade Music School</title>
        <meta name='description' content='The best music orchestration course Music orchestration tutorial orchestration course online film scoring course online best orchestration courses orchestration tutorials free orchestration course free music composition course free film scoring course free learning orchestration for beginners music orchestration for beginners film scoring for beginners composing music online
' />
      </Helmet>
      <HeroSection learnText={learnText}  learnFile={learnFile}/>
      <Cards />
    </>
  )
}

export default Downloads