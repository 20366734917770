import React, { useState } from 'react'
import './Header.scss'
import { ReactComponent as MiniLogo } from './../../../../assets/logo/LogoMini.svg'
import { ReactComponent as Settings } from './../../../../assets/icons/Settings.svg'
import { ReactComponent as VectorRight } from './../../../../assets/icons/VectorRight.svg'
import { useNavigate } from 'react-router'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
const Header = ({enrolStatus,onCompleteAndContinue,courseId}) => {

    const [isEnrolling, setIsEnrolling] = useState(false);
    const handleCoompleete=()=>{
       
    }
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const navigate = useNavigate()
    const handleSettingToggle = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };
    const handleClick = () => {
        navigate('/');
        window.scrollTo(0, 0);
    }

 

    return (
        <div className='header-general'>
            <div className='header-left'>
                <MiniLogo onClick={handleClick} style={{ cursor: "pointer" }} />
                {/* <div
                    className='icon-settings'
                    onClick={handleSettingToggle}
                >
                    <Settings />
                </div> */}
                {isSettingsOpen && (
                    <div
                        className='player-settings'
                        style={{ opacity: isSettingsOpen ? 1 : 0 }}
                    >
                        <div className='field'>
                            <span>AutoPlay</span>
                            <label className="switch">
                                <input className='switch-input' type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className='field'>
                            <span>Autocomplete</span>
                            <label className="switch">
                                <input className='switch-input' type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                )}
            </div>
              {
                (enrolStatus===true&& <div className='header-right'>
                <button onClick={e=>onCompleteAndContinue()}>
                    Complete and Continue
                    <VectorRight className='right' />
                </button>
          
            </div>
            
            
            
            )
              }
        </div>

    )
}

export default Header