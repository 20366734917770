import React , { useEffect, useState } from 'react'
import './Cards.scss'
import videoImage from './../../../../assets/images/Video-image.png'
import { ReactComponent as Play } from './../../../../assets/svg/Play.svg'
import { ReactComponent as FilmIcon } from './../../../../assets/icons/Film.svg'
import { ReactComponent as Download } from './../../../../assets/icons/Download.svg'
import { useNavigate } from 'react-router-dom'
import  image  from '../../../../assets/icons/ab.png'
import 'typeface-roboto'

import axios from 'axios';
const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};


const Cards = () => {
    const [courses, setCourses] = useState([]);
    const [videoDurations, setVideoDurations] = useState({});
    const [activeVideo, setActiveVideo] = useState(null);

    useEffect(() => {
        const downloadContent = async () => {
            try {
                const response = await axios.get('https://api.asgarzademusicschool.com/api/v1/CourseContent/Downloads');
                setCourses(response.data.items);
            } catch (error) {
                // Hata işleme
            }
        };
        downloadContent();
    }, []);

    const handleMetadataLoaded = (videoLink, event) => {
        const videoElement = event.target;
        const duration = Math.round(videoElement.duration);
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        const formattedDuration = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        setVideoDurations(prevDurations => ({
            ...prevDurations,
            [videoLink]: formattedDuration
        }));
    };

    const handleImageClick = (courseId) => {
        setActiveVideo(courseId);
    };

    return (
        <section className='cards-downloads'>
            <div className="container">
                <ul className='cards-downloads-container'>
                    {courses.map((course, index) => (
                        <li key={index}>
                            <div style={{ width: "100%" }} className='image-side'>

                            <video 
                                        width="360" 
                                        height="200" 
                                        controls
                                        onLoadedMetadata={(e) => handleMetadataLoaded(course.videoLink, e)}
                                        poster={course.imageLink}
                                    >
                                        <source src={course.videoLink} type="video/mp4" />
                                    </video>
                               
                           
                            </div>
                            <div style={{ width: "100%" }} className='info_card'>
                                <div className='info_card_content'>
                                    <p style={{ textAlign: "center",fontSize:"18px"}}>{course.title}</p>
                                </div>
                                <a href={course.videoLink} style={{ width: "100%" }}>
                                    <button onClick={() => console.log('Download button clicked')}>
                                        <span style={{ cursor: 'pointer' }}>Download</span>
                                    </button>
                                </a>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}

export default Cards;