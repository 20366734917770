import React, { useEffect, useState, useRef } from "react";
import "./Testimonials.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonials } from "./../../../../redux/actions/testimonialsActions";

const Testimonials = () => {
  const dispatch = useDispatch();
  const testimonialsRef = useRef(null);
  const swiperRef = useRef(null);
  const { data } = useSelector((state) => state.testimonials);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const modalContentRef = useRef(null);
  const [shouldFetch, setShouldFetch] = useState(false);

  const fetchData = () => {
    dispatch(fetchTestimonials());
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
    }
  }, [shouldFetch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldFetch(true);
        }
      },
      { threshold: 0.1 }
    );

    if (testimonialsRef.current) {
      observer.observe(testimonialsRef.current);
    }

    return () => {
      if (testimonialsRef.current) {
        observer.unobserve(testimonialsRef.current);
      }
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      handleCloseModal();
    }
  };

  const handleCardClick = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTestimonial(null);
  };

  return (
    <section className="testimonials-section" ref={testimonialsRef}>
      <div className="container">
        <div className="testimonials-section-container">
          <h2>Testimonials</h2>
          <div className="testimonials">
            <Swiper
              className="testimonials_content"
              spaceBetween={30}
              initialSlide={1} // Ensure it starts with the first slide
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onImagesReady={() => {
                if (swiperRef.current) {
                  swiperRef.current.swiper.update();
                }
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {data && data.items ? (
                data.items.map((slide) => (
                  <SwiperSlide key={slide.id} className="thought-area">
                    <div
                      className="testimonial"
                      onClick={() => handleCardClick(slide)}
                    >
                      <div className="imageTest">
                        <img src={`${slide.imageUrl}`} alt={slide.imageName} />
                      </div>
                      <div className="opinion">
                        <div className="name-skill">
                          <h6>{slide.name}</h6>
                          <span>{slide.position}</span>
                        </div>
                        <div className="text-area">
                          <p>{slide.text}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                <p>No data available</p>
              )}
            </Swiper>
          </div>

          {isModalOpen && selectedTestimonial && (
            <div className={`modal ${isModalOpen ? "active" : ""}`}>
              <div className="modal-content" ref={modalContentRef}>
                <div  className="modalImage">
                  <img
                    src={selectedTestimonial.imageUrl}
                    alt={selectedTestimonial.imageName}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                 
                </div>
                <div className="modalInfo">
                  <span className="close-button" onClick={handleCloseModal}>
                    &times;
                  </span>
                  <h3>{selectedTestimonial.name}</h3>
                  <p>{selectedTestimonial.text}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
