import React, { useState ,useEffect} from 'react'
import './EditProfile.scss'
import { ReactComponent as ShowPassword } from './../../../../../assets/svg/ShowPassword.svg'
import { ReactComponent as HidePassword } from './../../../../../assets/svg/HidePassword.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

const EditProfile = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [user,SetUser]=useState();
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const navigate=useNavigate();
const [isLoading,setIsLoading]=useState(true);
const userData = {
    name: "" ,
    surname:"",
    email: "",
    password:"",
    confirmedPassword: ""
};

const handleProfileUpdate = async () => {
    const token = localStorage.getItem("token");
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`
        }
    };

    const userData = {
        name: name || user?.name, // use the state or fallback to the current value
        surname: surname || user?.surname,
        email: email || user?.email,
        password: password,
        confirmedPassword: confirmedPassword
    };

    try {
        const response = await axios.put('https://api.asgarzademusicschool.com/api/v1/Identity/Update', userData, axiosConfig);
        if (response.status === 200) {
            toast.success('Successfully!')
            navigate('/profile');
        }
    } catch (error) {
     
        toast((t) => (
            <span>
              Something went wrong. Ensure you enter accurate information
              <button onClick={() => toast.dismiss(t.id)}>
                Ok
              </button>
            </span>
          ));
        if (error.response && error.response.status === 401) {
            navigate("/login");
        }
    }
};

useEffect(() => {
    const token=localStorage.getItem("token");
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `bearer ${token}`
        }
      };
      
    try {
       const response= axios.get("https://api.asgarzademusicschool.com/api/v1/Identity/GetCurrentUser",
        axiosConfig);
        response.then(res=>{
            if(res?.status===200)
          {
            userData.name=res.data.user.name
            userData.surname=res.data.user.surname
            userData.email=res.data.user.email
    
            setName(res.data.user.name);
            setSurname(res.data.user.surname);
            setEmail(res.data.user.email);

                setIsLoading(false)
          }
        }).catch(error=>{
            if(error.response.status===401){
                navigate("/login")
            };
        })
    } catch (error) {
    }
     
  },[]);
    
    if(!isLoading){
    return (
        <div className='edit-profile'>
            {/* <div className='edit-profile_picture'>
                <img src={imageSrc || Picture} alt="editable_picture" />
                <div className='upload' onClick={handleImageUploadClick}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        id="fileInput"
                        style={{ display: 'none' }}
                    />
                    <Upload />
                    <span>Change</span>
                </div>
            </div> */}
            <div className='edit-profile_editable_area'>
                <div className='edit-profile_editable_area_profile'>
                    <h5>Profile</h5>
                    <ul>
                        <li className='full-inputs'>
                            <label htmlFor="">Name</label>
                            <input value={name} onChange={(e)=>setName(e.target.value)} placeholder={user?.name} />
                        </li>
                        <li className='full-inputs'>
                            <label htmlFor="">Surname</label>
                            <input value={surname}  onChange={(e)=>setSurname(e.target.value)} placeholder={user?.surname} />
                        </li>
                        <li className='full-inputs'>
                            <label htmlFor="">Email</label>
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder={user?.email}  />
                        </li>
                        <li className='full-inputs'>
                            <label htmlFor="password">Password</label>
                            <div className="input">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <div className='show-hide' onClick={togglePasswordVisibility}>
                                    {showPassword ? <HidePassword /> : <ShowPassword />}
                                </div>
                            </div>
                        </li>
                        <li className='full-inputs'>
                            <label htmlFor="password">ConfirmedPassword</label>
                            <div className="input">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirmedPassword"
                                    value={confirmedPassword}
                                    onChange={(e) => setConfirmedPassword(e.target.value)}
                                />
                                <div className='show-hide' onClick={togglePasswordVisibility}>
                                    {showPassword ? <HidePassword /> : <ShowPassword />}
                                </div>
                            </div>
                        </li>

                        <button onClick={handleProfileUpdate}>update</button>

                    </ul>
                </div>
            </div>
            <Toaster toastOptions={{
    className: '',
    style: {
        duration: 3000,
      border: '1px solid #713200',
      padding: '16px',
      color: '#713200',
      zIndex:"999999999999999999999"
    },
  }} className="test"  position="top-center"
  reverseOrder={false} />
        </div>
        
    )
}
}

export default EditProfile
