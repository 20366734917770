import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import "./LearnFrom.scss";

const LearnFrom = () => {
    const [galleryData, setGalleryData] = useState([]);

    useEffect(() => {
        // Verileri API'den çekme işlemi
        fetch('https://api.asgarzademusicschool.com/api/v1/Galery/GetAllGaleries')
            .then(response => response.json())
            .then(data => {
                setGalleryData(data.items);
            })
            .catch(error => console.error("Veri çekme hatası:", error));
    }, []);

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <section className='learnfrom'>
            <div className="container">
                <div className='learnfrom-container'>
                    <div className='learnfrom-container_text'>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {
                                galleryData.map((item, index) => (
                                    <div key={index} className="gallery-item">
                                        <img src={item.path} alt={`image-${index}`} />
                                        <p>{item.text}</p>
                                    </div>
                                ))
                            }
                        </Masonry>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LearnFrom;
