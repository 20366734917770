import React,{useEffect,useState}  from 'react'
import './PurchaseHistory.scss'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios'



const PurchaseHistory = () => {
    const [enrollments,setEnrollments]=useState([]);
const navigate=useNavigate();
const [isLoading,setIsLoading]=useState(true);
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  useEffect(() => {
    const token=localStorage.getItem("token");
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `bearer ${token}`
        }
      };
      
    try {
       const response= axios.get("https://api.asgarzademusicschool.com/api/v1/CourseEnroll/GetUserPurscahesAsync",
        axiosConfig);
        response.then(res=>{
            if(res?.status===200)
          {
                setEnrollments(res.data.data)
                setIsLoading(false)
          }
        }).catch(error=>{
            if(error.response.status===401){
                navigate("/login")
            };
        })
    } catch (error) {
    }
     
  },[]);
  if(!isLoading){
    return (
     
        <div className='purchase'>
              {enrollments.length>0?
            <div className='purchase-head'>
                <h6>Your purchase history</h6>
                {
  enrollments.map((enroll, index) => (
    enroll.paymentTransactions.map((payment, pindex) => (
      <ul key={`enroll-${index}-payment-${pindex}`}>
  <li style={{color: payment.isPaid ? "green" : "red"}}>
  {payment.isPaid ? "Paid" : "Unpaid"}
</li>

        <li>{payment.amount} $</li>
        <li>{formatDate(payment.dateForPayment)}</li>
        {/* <li style={{ cursor: 'pointer', color: '#d58b0e' }} onClick={() => navigate(`/video/${payment.id}`)}>Go to course</li> */}
      </ul>
    ))
  ))
}

                <div className='line'></div>
            </div>
            :
            <div className='purchase-empty'>
                <p>You currently do not have any purchases.</p>
                <p>You can take a look at our active courses <Link to="/profile">here</Link>.</p>
            </div>
  }
        </div>
    )
        }
}

export default PurchaseHistory