import React,{useState,useRef, useEffect} from 'react'
import './PricingOption.scss'
import axios from 'axios'
import { useParams,useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';

// toast.success('Successfully toasted!')

const PricingOption = ({price,globalPrice,code, handleCode,isMonth}) => {
    const { id,optionalParam1,optionalParam2 } = useParams();
    const [month,setMonth]=useState();
    const [modal,setModal]=useState({filrUrl:"",status:false});
    const customStyles = {
      zIndex:'9999999999999999',
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:"90%",
        height:"70%",
        zIndex:'9999999999999999',
      },
    };
    const iframeRef = useRef(null);
const handleMonth=(m)=>{
  setMonth(m)
}

    const navigate = useNavigate();
    if(optionalParam1==='cancel')
    {
      toast(
        "Payment Canceled",
        {
          duration: 6000,
        }
      );
    }else if(optionalParam1==='decline')
    {
      toast(
        "Payment declined",
        {
          duration: 6000,
        }
      );
    }else if(optionalParam1==='accept')
    {
      toast('Payment successfully', {
        icon: '👏',
      });
      navigate(`/video/${id}`)
    }else if(optionalParam1==="coupon"){
      handleCode(optionalParam2)
    }
  let enrolobj=  {

        "courseId": id,
        "month": month,
        "cuponCode": code
      }


    const token=localStorage.getItem("token");

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `bearer ${token}`
    }
  };
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(true);
  function openPopup(url) {
    // Popup penceresini aç
    const popupWindow = window.open(
      url,
      'popupWindow',
      'width=800,height=600,scrollbars=yes'
    );
    
    // Popup penceresine odaklan
    if (popupWindow) {
      popupWindow.focus();
    } else {
      alert('Popup blocked by browser');
    }
  }
  const handleEnrol = async () => {

     console.log(enrolobj);
    try {
      setIsEnrolling(true);
      const response = await axios.post(
        "https://api.asgarzademusicschool.com/api/v1/CourseEnroll/CreateCourseEnroll",
        enrolobj,
        axiosConfig
      );
      if(response.data.statusCode===200){
          
        window.location.href = response.data.url;
        setIsEnrolling(false);
      }else{
        toast.error('Something went wrong please try again!');
        setIsEnrolling(false);
      }
    } catch (error) {
     if(error.response.status===401){
      navigate("/login")
     }
      toast.error('Something went wrang please try again!');
      setIsEnrolling(false);
    }
  };
    return (
        <section className='pricing-section-course'>
            <div className="container">
                <div className="pricing-section-container">
                    <div className='pricing-section-container_first-part'>
                        <h3>Choose a Pricing Option</h3>
                        <div className="options">
                            <div className='option'>
                                <input onClick={e=>handleMonth(1)} type="radio" name="onetime" id="onetime" />
                                <label htmlFor="">
                                    <h5>{price}$</h5>
                                    <span>One time payment</span>
                                    <p>Get full access to the entire course forever!</p>
                                    <h6>Include Taxes</h6>
                                </label>
                            </div>
                            {
                              isMonth===true?(  <div  className='option'>
                              <input onClick={e=>handleMonth(3)} type="radio" name="onetime" id="onetime" />
                              <label htmlFor="">
                              <h5>3 payments of {(globalPrice/3).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}/month</h5>

                                  <span>Payment Plan (3 months)</span>
                                  <p>Get full access to the entire course forever!</p>
                                  <h6>Include Taxes</h6>
                              </label>
                          </div>):""
                            }
                          
                        </div>
                    </div>
                    <button disabled={isEnrolling} onClick={handleEnrol} className='enroll-button'>{isEnrolling ? 'Processing...' : 'Enroll now'}</button>
                </div>
            </div>

{
  modal.status  &&
  <Modal
            
  isOpen={modalIsOpen}
  contentLabel="Example Modal"
  style={customStyles}
>
              <iframe id='te'  ref={iframeRef} style={{width:"100%",height:"100%"}} src={modal.filrUrl} title="description"></iframe>
</Modal>
}
          



            <Toaster toastOptions={{
    className: '',
    style: {
        duration: 3000,
      border: '1px solid #713200',
      padding: '16px',
      color: '#713200',
      zIndex:"999999999999999999999"
    },
  }} className="test"  position="top-center"
  reverseOrder={false} />
        </section>
    )
}

export default PricingOption