import React, { useEffect, useState, useRef } from 'react';
import "./AvailableCourses.scss";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../../../redux/actions/coursesActions';

const AvailableCourses = ({ scrollToSubscribeSection }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const coursesRef = useRef(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const { data } = useSelector((state) => state.courses);

    const handleClick = (courseId) => {
        navigate(`/video/${courseId}`);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (shouldFetch) {
            dispatch(fetchCourses());
        }
    }, [shouldFetch, dispatch]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setShouldFetch(true);
                }
            },
            { threshold: 0.1 }
        );

        if (coursesRef.current) {
            observer.observe(coursesRef.current);
        }

        return () => {
            if (coursesRef.current) {
                observer.unobserve(coursesRef.current);
            }
        };
    }, []);

    return (
        <section className='available-courses' ref={coursesRef}>
            <div className="container">
                <div className='available-courses-container'>
                    <h2>
                        Available  <span className='special-header-text'>Courses</span>
                    </h2>
                    <ul className='available-courses-container_cards'>
                        {data && data.items ? (
                            data.items.map((course) => (
                                <li key={course.id} onClick={() => handleClick(course.id)}>
                                    <div className='image-side'>
                                        <img src={course.image} alt="picture1" className='main_image' />
                                    </div>
                                    <div className='info_card'>
                                        <h6 dangerouslySetInnerHTML={{ __html: course.title }}></h6>
                                        <div className='info_card_owner'>
                                            <div className='owner'>
                                            <span dangerouslySetInnerHTML={{ __html: course.shortInfo }}></span>
                                            </div>
                                            <span className={`price ${course.isComming==true?"green":""}`}>


                                                {
                                                    course.isComming==true?
                                                    "Coming Soon":
                                                    course?.discountPrice === 0 ? 
        "$"+ course.price : 
        <>
            <del style={{fontWeight:"lighter"}}>${course.price}</del>
            &nbsp; &nbsp; 

            <span style={{color: 'rgb(235 87 87)'}}>
                ${course.discountPrice} &nbsp; 
                 SAVE ${course.price-course.discountPrice}
            </span>
        </>
                                                    
                                                }
    {
    }
</span> 
<p style={{textAlign:"right",marginTop:"10px"}}>{course.isTax===true? "(Including VAT)":"(Excluding VAT)"}</p>

                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <p>No data available</p>
                        )
                        }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default AvailableCourses;
