import React, { useEffect, useState, useRef } from "react";
import "./FAQ.scss";
import Vector from "./../../../../assets/icons/VectorBottom.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestions } from "./../../../../redux/actions/questionsActions";

const FAQ = () => {
  const dispatch = useDispatch();
  const faqRef = useRef(null);
  const { data, isLoading } = useSelector((state) => state.questions);
  const [expandedItems, setExpandedItems] = useState({});
  const [shouldFetch, setShouldFetch] = useState(false);
console.log(data)
  const fetchData = () => {
    dispatch(fetchQuestions());
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
    }
  }, [shouldFetch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldFetch(true);
        }
      },
      { threshold: 0.1 }
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      if (faqRef.current) {
        observer.unobserve(faqRef.current);
      }
    };
  }, []);

  const handleItemClick = (itemId) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [itemId]: !prevExpandedItems[itemId], 
    }));
  };

  const contentRef = useRef(null);

  return (
    <section className="accordion" ref={faqRef}>
      <div className="container">
        <div className="accordion-container">
          <h3>Frequently <span style={{color:"#d58b0e"}}>Asked Questions</span></h3>
          <h4>FAQ</h4>
          <ul className="accordion-container_lists">
            {isLoading ? (
              <p> Loading...</p>
            ) : data && data.items ? (
              data.items.map((item) => (
                <li onClick={() => handleItemClick(item.id)} key={item.id}>
                  <div className="accordion-question">
                    <span>{item.title}</span>
                    <img
                      src={Vector}
                      alt="vector"
                      className={expandedItems[item.id] ? "rotate" : ""}
                    />
                  </div>
                  <p
                    ref={contentRef}
                    style={{
                      maxHeight: expandedItems[item.id]
                        ? `${500}px`
                        : "0",
                 
                    }}
                  >
                    {item.description}
                  </p>
                </li>
              ))
            ) : (
              <p>No data available</p>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
