// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
const PrivacyPolicy = () => {
    return (
    <>
    <div className="WordSection1" style={{padding:"150px 30px"}}>
      <h2 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <span style={{ fontSize: '14.0pt', lineHeight: '  40px', fontFamily: 'Helvetica'}}>Privacy Policy

</span>
      </h2>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px'}}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        This Privacy Policy governs the manner in which the Asgarzade Music School collects, uses,
maintains, and discloses information collected from users (each, a “Student”) of the School. This
Privacy Policy applies to the Asgarzade Music School and all Courses offered by the Asgarzade
Music School.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>Personal identification information</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        We may collect personal identification information from Students in a variety of ways, including, but
not limited to, when Students enroll in the School or a Course within the School, subscribe to a
newsletter, and in connection with other activities, services, features, or resources we make available
in our School. Students may visit the Asgarzade Music School anonymously. We will collect personal
identification information from Students only if they voluntarily submit such information to us. Students
can refuse to supply personal identification information but doing so may prevent them from engaging
in certain Asgarzade Music School related activities.
        </span>
      </p>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>What personal data does Asgarzade Music School process during the initiation, conclusion,
and fulfillment of the agreement?</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        During the initiation, conclusion, and fulfillment of the Agreement with a data subject, the
following personal data of the data subject are processed by Asgarzade Music School:
Full name; Residential address; Personal Tax information; E-mail address; IP address; Signup
source; Device information; Operating system; Browser ID; Information about the application
used to read e-mails; Information about used hardware and internet connection; usage data, in
particular; Date and time, when an e-mail has been opened by the data subject; Campaign and
browser activity (e.g. which emails and/or web pages were opened); Delivery statistics of e-
mails and connected communication data.
• Your Bank account and Credit Card information remain secure as Asgarzade Music School
does not collect any personal data. All purchases and refund transactions will be conducted by
Kapital Bank of the Azerbaijan Republic.
        </span>
      </p>
      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>How we use collected information</span></b>
      </h3>
      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        Asgarzade Music School may collect and use Students’ personal identification information for the
following purposes:

        </span>
      </p>
      <ol style={{marginLeft:"5%",}}>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>• To improve customer service<br></br><br></br>
          The information you provide helps us respond to your customer service requests and support
needs more efficiently.</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>• To personalize user experience<br></br><br></br>
          We may use information in the aggregate to understand how our Students as a group use the
services and resources provided in our School.</span>
        </li>
        <li style={{ color: 'black', marginBottom: '11.25pt', lineHeight: 'normal'}}>
          <span style={{ fontFamily: 'Helvetica' }}>• To send periodic emails<br></br><br></br>
          We may use Student email addresses to send Students information and updates pertaining to
their order. Student email addresses may also be used to respond to Student inquiries,
questions, or other requests.</span>
        </li>
     

      </ol>


      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>Sharing your personal information</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        We do not sell, trade, or rent Student personal identification information to others.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>Third-party websites</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        A student may find advertising or other content in our School that links to the websites and services of
our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the
content or links that appear on these websites and are not responsible for the practices employed by
websites linked to or from our School. In addition, these websites or services, including their content
and links, may be constantly changing. These websites and services may have their own privacy
policies and customer service policies. Browsing and interaction on any other website, including
websites that have a link to our Student, is subject to that website's own terms and policies.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}> Changes to this Privacy Policy</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        The Asgarzade Music School has the discretion to update this Privacy Policy at any time. We
encourage Students to frequently check this page for any changes. You acknowledge and agree that
it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>Your acceptance of these terms</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        By enrolling in the Asgarzade Music School, you signify your acceptance of this Privacy Policy. If you
do not agree to this Privacy Policy, please do not enroll in the School. Your continued enrollment in
the Asgarzade Music School following the posting of changes to this Privacy Policy will be deemed
your acceptance of those changes.
        </span>
      </p>

      <h3 style={{ marginTop: '15.0pt', marginBottom: '7.5pt', lineHeight: '  40px' }}>
        <b><span style={{ fontFamily: 'Helvetica'   }}>Refund of the payments</span></b>
      </h3>

      <p style={{ marginBottom: '16.5pt', lineHeight: '  40px' }}>
        <span style={{ fontFamily: 'Helvetica'   }}>
        Students have a 15-day money-back guarantee if they are not satisfied with the course materials.
NOTE: Asgarzade Music School will not grant a refund if students watch more than 25% of the
course material. For verification purposes, the 'Student Progress' indicator on the website is utilized.
To qualify for a refund, please make sure to request it within 15 days after your purchase.
NOTE: Asgarzade Music School reserves the right to exclude customers from future purchases after
more than two refund requests.
        </span>
      </p>

<span>
“Asgarzade Music School” Online Educational Platform    <br></br>                                         
Owner: Rovshan Asgarzade<br></br>  Azerbaijan / Baku / Yasamal District / H.Javid avenue / 46/79 <br></br>  
AZ1141 <br></br>  
Tax ID: AZ 8503481572  <br></br> 
Email: <br></br> 
asgarzademusicschool@gmail.com <br></br> 
secretsoforchestration@gmail.com   <br></br>  
Baku, November 2023
</span>
    </div>

    </>
    )

}

export default PrivacyPolicy