let IsLoading = true;

export function setLoadingStatus(status) {
    IsLoading = status;
}

export function getLoadingStatus() {
  return IsLoading
  ;
}




